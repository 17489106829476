import React from 'react'
import { Link } from 'react-router-dom'
import thefatred_img from '../img/thefatred.png'
import './css/Blog.css'
import { Container } from 'react-bootstrap'

const Blog = () => {
  return (
    <Container fluid className='blogContainer' id="mainContainer">
      
      <h4 >
        Follow my analytics/tactics blog on Arsenal: <a href="https://thefatredcom.wordpress.com/">The Fat Red</a>
        
        </h4>

        {/* <img src= { 'url(../img/thefatred_img.png)'} /> */}

    </Container>
  )
}

export default Blog
