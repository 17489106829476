import React from "react";
import {
  LinkedinShareButton,
  LinkedinIcon,
  XIcon,
  EmailIcon,
} from "react-share";
import "./css/Contact.css";

const Contact = () => {
  return (
    <div className="contact"  id="mainContainer">
        <LinkedinShareButton url="https://www.linkedin.com/in/haghanim/">
          <LinkedinIcon />
        </LinkedinShareButton>
    </div>
  );
};

export default Contact;
