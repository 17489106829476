import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PostCard from "./PostCard";
import "./css/Hobbies.css";

import golf_team_img from "../img/golf_team_sq.png";
import running_img from "../img/marathon_milo_sq.png";
import chess_img from "../img/lichess_logo.png";

const Hobbies = () => {
  return (
    <Container fluid className="hobbiesContainer" id="mainContainer">
      
      <Row className="hobbyRow">
        <Col xs={12} sm={6} lg={4} xl={3} xxxl={2} className="hobbyCard">
          <PostCard
            title="Golf"
            description="NCAA D1 Golf @ UPenn & LMU"
            img={golf_team_img}
            linkText="Career stats"
            linkUrl="https://pennathletics.com/sports/mens-golf/roster/mark-haghani/19861"
          />
        </Col>

        <Col xs={12} sm={6} lg={4} xl={3} xxxl={2} className="hobbyCard" id="r">
          <PostCard
            title="Running"
            description="London & LA Marathon"
            img={running_img}
            linkText="Strava"
            linkUrl="https://www.strava.com/dashboard"
          />
        </Col>

        <Col xs={12} sm={6} lg={4} xl={3} xxxl={2} className="hobbyCard">
          <PostCard
            title="Chess"
            description="Challenge me to a game"
            img={chess_img}
            linkText="lichess"
            linkUrl="https://lichess.org/@/haghanim"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Hobbies;
