import React from "react";
import { Link } from "react-router-dom";
import { Container, Image, Card, Row, Col } from "react-bootstrap";

import "./css/About.css";
import img from "../img/headshot_golf.png";

const About = () => {
  return (
    <Container className="aboutContainer" id="mainContainer">
      <Row className="justify-content-center">
        <Card className="aboutCard">
          <Card.Img variant="top" src={img} />
          <Card.Body>
            <Card.Title>About me</Card.Title>
            <Card.Text>
              I've always enjoyed learning about new technologies and applying them to solve real-world problems. 
              Working as an SDE at <b> Amazon Robotics </b> and <a href="https://www.mia-share.com/"> Mia Share</a> gave me crucial
              experience in building business-oriented software products. I entered the space at my favorite company as a kid, 
              <a href="https://statsbomb.com/"> StatsBomb</a>, 
              the industry standard for football data collection in Europe. 
              
              Originally from London, I have since lived in LA, Philadelphia, Seattle, and NYC. 
              Take a look outside of my professional life by exploring the other pages above. 
              {/* or contact me through 
              <a href="https://www.linkedin.com/in/haghanim/"> LinkedIn</a>. */}
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
};

export default About;
