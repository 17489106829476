import { React } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import "./css/Home.css";
import lakeImage from "../img/lake.png"; // Import your image

const Home = () => {
  return (
    <Container fluid className="homeContainer">
      <div className="content">
        <h1> Mark Haghani </h1>
        {/* <p> </p> */}
        <a href="/about"> Explore </a>
      </div>
    </Container>
  );
};

export default Home;
