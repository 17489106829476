import { Card, Button } from "react-bootstrap/";
import React from "react";

import "./css/PostCard.css";

const PostCard = ({ title, description, img, linkText, linkUrl }) => {
  return (
    <Card className="cardComp">
      <Card.Img variant="top" src={img} className="cardImg" />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
      <Card.Body>
        <Card.Link  href={linkUrl} target="_blank">
          <Button id="button1"> {linkText} </Button>
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

export default PostCard;
