import {
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Nav,
} from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./Home";
import Contact from "./Contact";
import About from "./About";
import Projects from "./Projects";
import Hobbies from "./Hobbies";
import Blog from "./Blog";

import React from "react";

import "./css/NavbarComp.css";

const NavbarComp = () => {
  return (
    <div>
      <Navbar bg="dark" variant={"dark"} expand="sm" className="navb">
        <Navbar.Brand href="#" className="navbarBrand">
          <Nav.Link as={Link} to="/">
            Mark Haghani
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/hobbies">
              Hobbies
            </Nav.Link>
            <Nav.Link as={Link} to="https://thefatredcom.wordpress.com" target="_blank"> 
              Blog
            </Nav.Link>
            <Nav.Link as={Link} to="https://github.com/haghanim" target="_blank"> 
              Github
            </Nav.Link>
            <Nav.Link as={Link} to="https://www.linkedin.com/in/haghanim/" target="_blank"> 
              LinkedIn
            </Nav.Link>
            {/* 
              <Nav.Link as={Link} to="/blog">
                Blog
              </Nav.Link> */}
            {/* <Nav.Link as={Link} to="/projects">
              Projects
            </Nav.Link> */}
            {/* <Nav.Link as={Link} to="/contact">
              Contact
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarComp;
