import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/NavbarComp';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import Projects from "./components/Projects";
import Hobbies from "./components/Hobbies";
import Blog from "./components/Blog";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<div> <NavbarComp/> <About/>   </div>} />
        <Route path="/hobbies" element={<div> <NavbarComp/><Hobbies />   </div>} />
        <Route path="/blog" element={<div> <NavbarComp/><Blog />   </div>} />
        <Route path="/projects" element={<div> <NavbarComp/><Projects />   </div>} />
        <Route path="/contact" element={<div> <NavbarComp/><Contact />   </div>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;