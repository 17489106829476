import React from "react";
import Post from "./Post"; // Assuming your Post component file is in a folder named "components"

import { Container } from "react-bootstrap";
import golf_paper_img from "../img/golf_paper.png";

import './css/Projects.css'

const Projects = () => {
  return (
    <Container fluid className="projectsContainer">

      {/* <Post
        title="Project 1"
        description="Description of Project 1."
        imageUrl="https://example.com/project2.jpg"
        link="https://example.com/project2"
      />

      <Post
        title="Project 1"
        description="Description of Project 1."
        imageUrl="https://example.com/project2.jpg"
        link="https://example.com/project2"
      /> */}

      <Post
        title="Golf and Investment Strategy Short Note"
        description="Co-wrote paper about data-driven golf strategy and its similarities to and lessons for financial investing"
        imageUrl={golf_paper_img}
        link="https://elmwealth.com/better-golfer-better-investor/"
      />
      {/* Add more Post components as needed */}
      {/* <Post
        title="Project 1"
        description="Description of Project 1."
        imageUrl="https://example.com/project2.jpg"
        link="https://example.com/project2"
      /> */}
    </Container>

    
  );
};

//       Golf and Investment Strategy Short Note
//         • Co-wrote paper about data-driven golf strategy and its similarities to and lessons for financial investing
//     </div>
//   )
// }

export default Projects;
