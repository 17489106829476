import React from 'react'
import "./css/Post.css";

const Post = ({ title, description, imageUrl, link }) => {
  return (
    <div className="post">
      <img src={imageUrl} alt={title} className="post-image" />
      <div className="post-content">
        <h2 className="post-title">{title}</h2>
        <p className="post-description">{description}</p>
        <a href={link} className="post-link" target="_blank" rel="noopener noreferrer">
          View Project
        </a>
      </div>
    </div>
  );
};

export default Post;
